import React from "react";
import Layout from "../components/layout";
import { graphql, useStaticQuery } from "gatsby";
import AddressAndTransport from "../components/AddressAndTransport";
import { Helmet } from "react-helmet";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { parseNestedHTML } from "../Utils";
import { Button, Col, Container, Row, Stack } from "react-bootstrap";
import { GatsbyImage } from "gatsby-plugin-image";
import Slider from "react-slick";
import HTMLReactParser from "html-react-parser";
import SafeHtmlParser from "../components/safe-html-parser";
import NinjaStarSVG from "../components/svg/NinjaStarSVG";
const settings = {
	dots: true,
	dotsClass: "slick-dots slick-thumb",
	infinite: true,
	speed: 1000,
	slidesToShow: 1,
	slidesToScroll: 1,
	arrows: false,
	autoplay: true,
};
const FacilitiesAndAccessibility = () => {
	const data = useStaticQuery(graphql`
		query {
			wpFacility {
				facilitiesFields {
					facilities {
						body {
							item
						}
						subheading
						title
					}
					accessibility {
						body {
							item
						}
						subheading
						title
					}
					address {
						btnText
						title
						location {
							lat
							lng
						}
					}
					heroSection {
						btnText
						title
						slider {
							image {
								node {
									altText
									localFile {
										publicURL
										childImageSharp {
											original {
												height
												width
											}
											gatsbyImageData(
												formats: [AUTO, WEBP]
												quality: 100
												transformOptions: { cropFocus: CENTER, fit: CONTAIN }
												layout: CONSTRAINED
												placeholder: BLURRED
											)
										}
									}
								}
							}
						}
					}
					hungryNinja {
						body
						btnText
						subheading
						title
						image {
							node {
								altText
								localFile {
									publicURL
									childImageSharp {
										original {
											height
											width
										}
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
						image2 {
							node {
								altText
								localFile {
									publicURL
									childImageSharp {
										original {
											height
											width
										}
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
					vehicleStatus {
						body
						title
						icon {
							node {
								sourceUrl
								altText
								localFile {
									publicURL
									childImageSharp {
										original {
											height
											width
										}
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
				}
				seoFields {
					localBusinessSchema
					metaDescription
					opengraphDescription
					opengraphTitle
					title
					image {
						node {
							altText
							localFile {
								publicURL
								childImageSharp {
									original {
										height
										width
									}
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
				}
			}
			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);
	const siteUrl = data.site.siteMetadata.siteUrl;
	const FNAPage = data.wpFacility.facilitiesFields;
	const seoFields = data.wpFacility.seoFields;

	// console.log(seoFields.title);

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Facilities And Accessibility",
				item: {
					url: `${siteUrl}/facilities-and-accessibility`,
					id: `${siteUrl}/facilities-and-accessibility`,
				},
			},
		],
	};
	return (
		<div className="FacilitiesAndAccessibility">
			<Helmet>
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>

			<GatsbySeo
				title={seoFields?.title}
				description={seoFields?.metaDescription}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/facilities-and-accessibility`,
					title: seoFields?.opengraphTitle,
					description: seoFields?.opengraphDescription,
					images: [
						{
							url: `${seoFields?.image?.node?.localFile.publicURL}`,
							width: `${seoFields?.image?.node?.localFile.childImageSharp.original.width}`,
							height: `${seoFields?.image?.node?.localFile.childImageSharp.original.height}`,
							alt: `${seoFields?.image?.node?.altText}`,
						},
					],
				}}
			/>
			<Layout>
				<section className="position-relative ">
					<div className="position-relative hero-carousal">
						<div
							style={{
								zIndex: 1,
								background:
									"linear-gradient(0deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.40) 100%)",
								borderTop: "1px solid #FFF",
								borderBottom: "1px solid #FFF",
							}}
							className="position-absolute h-100 w-100"
						>
							<Container className="h-100">
								<Row className="align-items-center h-100">
									<Col>
										<div className="h-100 ">
											<div className="stylishText text-uppercase  ">
												<SafeHtmlParser
													htmlContent={FNAPage.heroSection.title}
												/>
											</div>
											<Button
												target="_blank"
												rel="noreferrer"
												href="https://ecom.roller.app/ninjaarenaeastbourne/checkout1/en/home"
												className="text-white text-uppercase w-100 w-md-auto fs-5 px-4 py-2"
											>
												{FNAPage.heroSection.btnText}
											</Button>
										</div>
									</Col>
								</Row>
							</Container>
						</div>
						<div className="slider-container ">
							<Slider {...settings}>
								{FNAPage.heroSection.slider.map((slider, i) => (
									<div key={i}>
										<GatsbyImage
											className="images-height"
											image={
												slider.image?.node.localFile?.childImageSharp
													?.gatsbyImageData
											}
											style={{
												objectFit: "cover",
												width: "100%",
											}}
											alt={slider.image?.node?.altText}
										/>
									</div>
								))}
							</Slider>
						</div>
					</div>
				</section>
				<section className="pt-5 pt-lg-7">
					<Container>
						<Row className="g-4 h-100">
							<Col lg={6}>
								<div
									className="p-4 h-100"
									style={{ backgroundColor: "#181819", borderRadius: 8 }}
								>
									<h2 className="display-5  text-uppercase">
										{FNAPage.facilities.title}
									</h2>

									<p className="text-white fs-5 py-3">
										{FNAPage.facilities.subheading}
									</p>
									<div className="d-md-none">
										{FNAPage.facilities.body.map(({ item }, i) => (
											<Stack
												direction="horizontal"
												className="align-items-start"
												gap={1}
												key={i}
											>
												<NinjaStarSVG
													style={{ minWidth: "15px", maxWidth: "15px" }}
												/>
												<p className="text-white  pt-1">{item}</p>
											</Stack>
										))}
									</div>
									<div className="d-none d-md-block">
										{FNAPage.facilities.body.map(({ item }, i) => (
											<Stack
												direction="horizontal"
												className="align-items-start"
												gap={2}
												key={i}
											>
												<NinjaStarSVG
													style={{ minWidth: "20px", maxWidth: "20px" }}
												/>
												<p className="text-white  pt-1">{item}</p>
											</Stack>
										))}
									</div>
								</div>
							</Col>
							<Col lg={6}>
								<div
									className="p-4 h-100"
									style={{ backgroundColor: "#181819", borderRadius: 8 }}
								>
									<h2 className="display-5  text-uppercase">
										{FNAPage.accessibility.title}
									</h2>

									<p className="text-white fs-5 py-3">
										{FNAPage.accessibility.subheading}
									</p>
									<div className="d-md-none">
										{FNAPage.accessibility.body.map(({ item }, i) => (
											<Stack
												direction="horizontal"
												className="align-items-start"
												gap={1}
												key={i}
											>
												<NinjaStarSVG
													style={{ minWidth: "15px", maxWidth: "15px" }}
												/>
												<p className="text-white  pt-1">{item}</p>
											</Stack>
										))}
									</div>
									<div className="d-none d-md-block">
										{FNAPage.accessibility.body.map(({ item }, i) => (
											<Stack
												direction="horizontal"
												className="align-items-start"
												gap={2}
												key={i}
											>
												<NinjaStarSVG
													style={{ minWidth: "20px", maxWidth: "20px" }}
												/>
												<p className="text-white  pt-1">{item}</p>
											</Stack>
										))}
									</div>
								</div>
							</Col>
						</Row>
					</Container>
				</section>
				<section className="py-5 py-lg-7">
					<Row className="align-items-center gx-0">
						<Col lg={6}>
							<div
								style={{
									position: "relative",
									display: "inline-block", // Or 'block' depending on your layout
								}}
							>
								<div
									style={{
										position: "absolute",
										top: 0,
										right: 0,
										bottom: 0,
										left: 0,
										background:
											"linear-gradient(to right, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%)",
										pointerEvents: "none", // Allows interaction with the GatsbyImage
										zIndex: 1, // Ensures the overlay is above the image
									}}
								></div>
								<GatsbyImage
									image={
										FNAPage.hungryNinja.image?.node.localFile?.childImageSharp
											?.gatsbyImageData
									}
									className="w-100"
									style={{
										objectFit: "cover",
										width: "100%",
									}}
									alt={FNAPage.hungryNinja.image?.node?.altText}
								/>
							</div>
						</Col>
						<Col className="px-lg-8 p-4 py-lg-0" lg={6}>
							<Stack className="pb-4" direction="horizontal">
								<h2 className="display-5  me-5 text-uppercase">
									{FNAPage.hungryNinja.title}
								</h2>
								<GatsbyImage
									image={
										FNAPage.hungryNinja.image2?.node.localFile?.childImageSharp
											?.gatsbyImageData
									}
									style={{ objectFit: "contain", width: 120, height: 70 }}
									alt={FNAPage.hungryNinja.image2?.node?.altText}
								/>
							</Stack>
							<p className="text-white">{FNAPage.hungryNinja.subheading}</p>
							<div>
								<SafeHtmlParser htmlContent={FNAPage.hungryNinja.body} />
							</div>

							<Button
								target="_blank"
								rel="noreferrer"
								href="https://ninja-arena-eastbourne.rjmdigital.net/wp-content/uploads/2024/09/Newspaper-Style-Food-Menu-3.pdf"
								className="text-white text-uppercase w-100 w-md-auto fs-5 px-4 py-2"
							>
								{FNAPage.hungryNinja.btnText}
							</Button>
						</Col>
					</Row>
				</section>
				<section className="pb-5 pb-lg-7">
					<AddressAndTransport
						address={FNAPage.address}
						vehiclesStatus={FNAPage.vehicleStatus}
					/>
				</section>
			</Layout>
		</div>
	);
};

export default FacilitiesAndAccessibility;
